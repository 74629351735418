<template>
  <div>
    <div>
      <label>Company Headquarter</label>
      <div class="mt-2 flex">
        <div
          class="
            flex
            input-wrapper
            items-center
            border border-gray-border
            bg-white
            w-full
            rounded-l
          "
        >
          <GmapAutocomplete @place_changed="setPlace" />
        </div>
        <div>
          <button
            type="button"
            @click="addMarker"
            class="px-6 rounded-r text-white bg-purple h-full w-full"
          >
            Set
          </button>
        </div>
      </div>
    </div>
    <br />
    <div>
      <GmapMap :center="center" :zoom="12" style="width: 100%; height: 400px">
        <GmapMarker :position="marker" @click="center = marker.position" />
      </GmapMap>
    </div>
  </div>
</template>

<script>
import Button from "../core/Button.vue";

export default {
  name: "CompanyLocationMapInput",
  components: { Button },
  data() {
    return {
      center: { lat: 45.508, lng: -75.587 },
      currentPlace: null,
      marker: null,
    };
  },
  mounted() {
    this.geoLocate();
  },
  methods: {
    setPlace(place) {
      this.currentPlace = place;
    },
    geoLocate: function () {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },
    addMarker() {
      if (!this.currentPlace) return false;

      const marker = {
        lat: this.currentPlace.geometry.location.lat(),
        lng: this.currentPlace.geometry.location.lng(),
      };

      this.marker = marker;
      this.center = marker;

      // this.$emit("location", marker);
      console.log(this.currentPlace.formatted_address);
      this.$emit("location", this.currentPlace.formatted_address);
    },
  },
};
</script>

<style scoped lang="scss">
.pac-target-input {
  @apply pl-3 w-full focus:outline-none;
}

.input-wrapper {
  height: 42px;
}
</style>