<template>
  <div>
    <div v-if="companyDataLoaded" class="bg-gray-main py-16 mb-16">
      <ValidationObserver v-slot="{ invalid }" ref="companyForm">
        <form @submit.prevent="submitCompanyEntry">
          <div class="container">
            <header class="mb-4">
              <h2 class="text-3xl font-bold">Add Company Entry</h2>
              <p>Thank you for helping us maintain accurate information.</p>
            </header>
            <div class="mt-16">
              <div class="shadow p-8 bg-white mt-8">
                <h4 class="text-xl font-bold">Company Information</h4>
                <div class="grid md:grid-cols-2 gap-6 mt-6">
                  <div>
                    <TextBox
                      id="companyName"
                      type="text"
                      label="Name"
                      rules="required"
                      v-model="companyForm.name"
                    />
                  </div>
                  <div>
                    <div>
                      <TextBox
                        label="Website"
                        type="url"
                        id="company-site"
                        placeholder="e.g. www.facebook.com"
                        v-model="companyForm.website"
                        :icon="['fas', 'globe']"
                      />
                    </div>
                  </div>
                  <div>
                    <div>
                      <TextBox
                        label="Founded Year"
                        type="number"
                        id="founded-year"
                        placeholder="e.g. 1996"
                        rules="required"
                        v-model="companyForm.estdAt"
                        :icon="['fas', 'calendar']"
                      />
                    </div>
                  </div>
                  <div>
                    <div>
                      <TextBox
                        label="Est. Employees"
                        type="number"
                        id="est-employees"
                        placeholder="e.g. 200"
                        v-model="companyForm.estEmployees"
                        :icon="['fas', 'users']"
                      />
                    </div>
                  </div>
                  <div>
                    <div>
                      <TextArea
                        label="About"
                        id="company-about"
                        rules="required"
                        v-model="companyForm.about"
                      />
                    </div>
                  </div>
                  <div>
                    <div>
                      <ImageUpload
                        label="Upload Company Logo"
                        @input="handlePhotoUpload"
                        :value="companyForm.logoUrl"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <company-location-map-input @location="setLocation"  />
                </div>
              </div>
              <div class="shadow p-8 bg-white mt-8">
                <h4 class="text-xl font-bold">User Information</h4>
                <div class="mt-6">
                  <div>
                    <TextBox
                      id="companyName"
                      type="email"
                      label="Email"
                      rules="required"
                      v-model="userEmail"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-16 flex items-center justify-center">
              <Button
                :loading="formSubmitting"
                type="submit"
                :disabled="invalid"
                text="Submit for Review"
              />
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <div v-else>
      <Loading />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Button from "@/components/core/Button";
import TextBox from "@/components/core/TextBox";
import { uploadAsset } from "@/helpers/firebase";
import Loading from "../components/core/Loading.vue";
import TextArea from "../components/core/TextArea.vue";
import ImageUpload from "@/components/core/ImageUpload";
import CompanyLocationMapInput from "../components/derived/MapInput.vue";

export default {
  name: "AddCompanyEntry",
  components: {
    Button,
    TextBox,
    TextArea,
    Loading,
    ImageUpload,
    CompanyLocationMapInput,
  },
  computed: {
    ...mapGetters(["company"]),
  },
  data() {
    return {
      companyForm: {
        name: "",
        website: "",
        estdAt: null,
        estEmployees: null,
        logoUrl: null,
        location: null,
      },
      userEmail: null,
      formSubmitting: false,
      companyDataLoaded: false,
    };
  },
  methods: {
    ...mapActions(["addCompanyEntry", "fetchCompany"]),
    submitCompanyEntry() {
      this.formSubmitting = true;

      let companyEntryData = {
        companyId: this.$route.params.id,
        details: this.companyForm,
        submittedBy: this.userEmail,
      };

      this.addCompanyEntry(companyEntryData)
        .then((response) => {
          console.log("Response after data entry");
          console.log(response);

          this.$swal({
            title: "Entry Submitted",
            text: "Thank you for your contribution to the effort to make salary cultures transparent.",
            icon: "success",
            confirmButtonText: "Go to Home",
          }).then((result) => {
            if (result.isConfirmed) this.$router.push({ name: "Home" });
          });
        })
        .catch((err) => {
          console.error(err.message);
          this.formSubmitting = false;
        });
    },
    async handlePhotoUpload(files) {
      console.log(files);
      let photoPaths = "";
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        let url;
        if (file instanceof File) {
          try {
            url = await uploadAsset(file);
          } catch (e) {
            console.log(e);
          }
        } else url = file; // file is "null"
        if (photoPaths && photoPaths.length) photoPaths += "|" + url;
        else photoPaths = url;
      }

      console.log(photoPaths);
      this.companyForm.logoUrl = photoPaths;
    },
    setLocation(location) {
      this.companyForm.location = location;
    },
  },
  async created() {
    let id = this.$route.params.id;

    await this.fetchCompany(id);

    this.companyDataLoaded = true;

    // Populate models
    this.companyForm.name = this.company.name;
  },
};
</script>

<style>
</style>