<template>
  <div class="mb-4" :class="wrapper_class">
    <label v-if="label" :for="id"
      >{{ label }} <span v-if="isRequired" class="text-red-500">*</span></label
    >
    <validation-provider :rules="rules" v-slot="{ errors }">
      <div
        class="
          flex
          input-wrapper
          items-center
          border border-gray-border
          bg-white
          w-full
          rounded
        "
        :class="input_wrapper_class"
      >
        <textarea
        ref="textarea"
          :id="id"
          @input="updateValue"
          @change="handleChange"
          class="h-32 pt-3 pl-3 w-full focus:outline-none"
        ></textarea>
      </div>
      <div class="error pt-1" v-if="errors.length">
        <small
          class="text-red-500 text-sm"
          v-for="(error, index) in errors"
          :key="index"
          >{{ error }}</small
        >
      </div>
      <small v-if="subtitle" class="text-black-light">{{ subtitle }}</small>
    </validation-provider>
  </div>
</template>

<script>
export default {
  name: "TextArea",
  props: {
    id: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hide_error: {
      type: Boolean,
      default: false,
    },
    label: String,
    rules: String,
    wrapper_class: String,
    input_wrapper_class: String,
    subtitle: String,
  },
  computed: {
    isRequired() {
      return this.rules.includes("required");
    },
  },
  methods: {
    updateValue() {
      this.$emit("input", this.$refs.textarea.value);
    },
    handleChange() {
      this.$emit("change", this.$refs.textarea.value);
    },
  },
};
</script>

<style scoped lang="scss">
.input-wrapper {
  margin-top: 6px;
}
</style>